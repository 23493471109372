import React from "react"
import { Link } from "gatsby"
import LayoutBasic from "../../components/LayoutBasic"
import BasicText from "../../components/BasicText"
import Info2column from "../../components/Info2column"
import CommonButton from "../../components/CommonButton"
import Seo from "../../components/Seo"
import Map01 from "../../images/interview_map_01_eja.svg"
import Map02 from "../../images/interview_map_02_eja.svg"
import Map03 from "../../images/interview_map_03_eja.svg"
import FlagMm from "../../images/flag_mm.svg"
import FlagJp from "../../images/flag_jp.svg"
import FlagId from "../../images/fkag_id.svg"
import FlagVn from "../../images/flag_vn.svg"
import RubyConvert from "../../components/RubyConvert"

import css from "./stylesheets/interview.module.scss"
import CenterContentLayout from "../../components/CenterContentLayout"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewTop}>
      <Seo title={`INTERVIEW`} />
      <LayoutBasic lang={`easyja`} style={{ marginBottom: `0` }}>
        <CenterContentLayout>
          <BasicText>
            <h1>INTERVIEW</h1>
            <p>
              <RubyConvert>{`{[日本/にほん]で}{[働/はたら]いている}{[人/ひと]たちへの}{インタビューです。}{インタビューは、}{[２人/ふたり]の}{[会話/かいわ]に}{なっています。}{[２人/ふたり]の}{[関係/かんけい]は、[夫婦/ふうふ]や}{[仕事/しごと]の[先輩/せんぱい]・}{[後輩/こうはい]など、}{いろいろです。}`}</RubyConvert>
              <br />
              <RubyConvert>{`{また、[働/はたら]いている}{[施設/しせつ]と}{[暮/く]らしている}{[家/いえ]を}{[見/み]せてくれました。}`}</RubyConvert>
              <br />
            </p>
            <Info2column id={`interview-01`} noBorder={true}>
              <div className={`only-pc`}>
                <Link to={`/interview/1`}>
                  <Img fluid={data.img01.childImageSharp.fluid} />
                </Link>
              </div>
              <div>
                <hr />
                <p className={css.date}>{`2020.3.25`}</p>
                <h2>
                  <RubyConvert>{`{わからない}{ことを}{[教/おし]えあいながら}{[働/はたら]く}`}</RubyConvert>
                </h2>

                <div className={`only-sp`}>
                  <Link to={`/interview/1`}>
                    <Img
                      className={css.spOnlyImg}
                      fluid={data.img01.childImageSharp.fluid}
                    />
                  </Link>
                </div>

                <p>
                  <RubyConvert>{`{[山崎/やまざき][僚恵/ともえ]さん}`}</RubyConvert>
                  <small>
                    <RubyConvert>{`{（23[歳/さい]）}`}</RubyConvert>
                  </small>
                  × <RubyConvert>{`{ジンジンモーさん}`}</RubyConvert>
                  <small>
                    <RubyConvert>{`{（27[歳/さい]）}`}</RubyConvert>
                  </small>
                </p>

                <div className={css.col2Styles}>
                  <div className={css.col2StylesLeft}>
                    <dl>
                      <dt>
                        <RubyConvert>{`[出身地/しゅっしんち]`}</RubyConvert>
                      </dt>
                      <dd>
                        <img src={FlagJp} alt="日本国旗" className={`flag`} />
                        <RubyConvert>{`[日本/にほん]`}</RubyConvert> ×{" "}
                        <img
                          src={FlagMm}
                          alt="ミャンマー国旗"
                          className={`flag`}
                        />
                        ミャンマー
                      </dd>

                      <dt>
                        <RubyConvert>{`{[2人/ふたり]の}[関係/かんけい]`}</RubyConvert>
                      </dt>
                      <dd>
                        <RubyConvert>{`{[同僚/どうりょう]}`}</RubyConvert>
                      </dd>
                    </dl>
                    <div className={css.commonButton + ` only-pc`}>
                      <CommonButton to={`/interview/1`} align={`left`}>
                        MORE
                      </CommonButton>
                    </div>
                  </div>
                  <div className={css.col2StylesRight}>
                    <img
                      style={{ width: `100%` }}
                      src={Map01}
                      alt="北海道札幌市"
                      width={392}
                      height={418}
                      className={css.mapImage}
                    />
                  </div>
                </div>
                <div className={`only-sp`}>
                  <CommonButton to={`/interview/1`}>MORE</CommonButton>
                </div>
              </div>
            </Info2column>

            <Info2column id={`interview-02`} noBorder={true}>
              <div className={`only-pc`}>
                <Link to={`/interview/2`}>
                  <Img fluid={data.img02.childImageSharp.fluid} />
                </Link>
              </div>
              <div>
                <hr />
                <p className={css.date}>{`2020.3.25`}</p>
                <h2>
                  <RubyConvert>{`{[自分/じぶん]の}{[心/こころ]と[体/からだ]を}{[大切/たいせつ]にしながら}{[働/はたら]く}`}</RubyConvert>
                </h2>

                <div className={`only-sp`}>
                  <Link to={`/interview/2`}>
                    <Img
                      className={css.spOnlyImg}
                      fluid={data.img02.childImageSharp.fluid}
                    />
                  </Link>
                </div>

                <p>
                  <RubyConvert>{`{[斉藤/さいとう][健一/けんいち]さん}`}</RubyConvert>
                  <small>
                    <RubyConvert>{`{（39[歳/さい]）}`}</RubyConvert>
                  </small>
                  × <RubyConvert>{`{トゥイーさん}`}</RubyConvert>
                  <small>
                    <RubyConvert>{`{（26[歳/さい]）}`}</RubyConvert>
                  </small>
                </p>

                <div className={css.col2Styles}>
                  <div className={css.col2StylesLeft}>
                    <dl>
                      <dt>
                        <RubyConvert>{`[出身地/しゅっしんち]`}</RubyConvert>
                      </dt>
                      <dd>
                        <img src={FlagJp} alt="日本国旗" className={`flag`} />
                        <RubyConvert>{`[日本/にほん]`}</RubyConvert> ×{" "}
                        <img
                          src={FlagVn}
                          alt="ベトナム国旗"
                          className={`flag`}
                        />
                        ベトナム
                      </dd>

                      <dt>
                        <RubyConvert>{`{[2人/ふたり]の}[関係/かんけい]`}</RubyConvert>
                      </dt>
                      <dd>
                        <RubyConvert>{`{[上司/じょうし]と}{[部下/ぶか]}`}</RubyConvert>
                      </dd>
                    </dl>
                    <div className={css.commonButton + ` only-pc`}>
                      <CommonButton to={`/interview/2`} align={`left`}>
                        MORE
                      </CommonButton>
                    </div>
                  </div>
                  <div className={css.col2StylesRight}>
                    <img
                      style={{ width: `100%` }}
                      src={Map02}
                      alt="長野県塩尻市"
                      width={392}
                      height={418}
                      className={css.mapImage}
                    />
                  </div>
                </div>
                <div className={`only-sp`}>
                  <CommonButton to={`/interview/2`}>MORE</CommonButton>
                </div>
              </div>
            </Info2column>

            <Info2column id={`interview-03`} noBorder={true}>
              <div className={`only-pc`}>
                <Link to={`/interview/3`}>
                  <Img fluid={data.img03.childImageSharp.fluid} />
                </Link>
              </div>
              <div>
                <hr />
                <p className={css.date}>{`2020.3.25`}</p>
                <h2>
                  <RubyConvert>{`{[働/はたら]く}{[場所/ばしょ]で、}{[子/こ]どもも}{[育/そだ]つ}`}</RubyConvert>
                </h2>

                <div className={`only-sp`}>
                  <Link to={`/interview/3`}>
                    <Img
                      className={css.spOnlyImg}
                      fluid={data.img03.childImageSharp.fluid}
                    />
                  </Link>
                </div>

                <p>
                  <RubyConvert>{`{リアさん}`}</RubyConvert>
                  <small>
                    <RubyConvert>{`{（33[歳/さい]）}`}</RubyConvert>
                  </small>
                  × <RubyConvert>{`{ウチュさん}`}</RubyConvert>
                  <small>
                    <RubyConvert>{`{（33[歳/さい]）}`}</RubyConvert>
                  </small>
                </p>
                <div className={css.col2Styles}>
                  <div className={css.col2StylesLeft}>
                    <dl>
                      <dt>
                        <RubyConvert>{`[出身地/しゅっしんち]`}</RubyConvert>
                      </dt>
                      <dd>
                        <img
                          src={FlagId}
                          alt="インドネシア国旗"
                          className={`flag`}
                        />
                        インドネシア ×{" "}
                        <img
                          src={FlagId}
                          alt="インドネシア国旗"
                          className={`flag`}
                        />
                        インドネシア
                      </dd>

                      <dt>
                        <RubyConvert>{`{[2人/ふたり]の}[関係/かんけい]`}</RubyConvert>
                      </dt>
                      <dd>
                        <RubyConvert>{`{[夫婦/ふうふ]、}{[同僚/どうりょう]}`}</RubyConvert>
                      </dd>
                    </dl>
                    <div className={css.commonButton + ` only-pc`}>
                      <CommonButton to={`/interview/3`} align={`left`}>
                        MORE
                      </CommonButton>
                    </div>
                  </div>
                  <div className={css.col2StylesRight}>
                    <img
                      style={{ width: `100%` }}
                      src={Map03}
                      alt="千葉県成田市"
                      width={392}
                      height={418}
                      className={css.mapImage}
                    />
                  </div>
                </div>
                <div className={`only-sp`}>
                  <CommonButton to={`/interview/3`}>MORE</CommonButton>
                </div>
              </div>
            </Info2column>
          </BasicText>
        </CenterContentLayout>
      </LayoutBasic>
    </div>
  )
}
export const query = graphql`
  query {
    img01: file(relativePath: { eq: "interview/01/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img02: file(relativePath: { eq: "interview/02/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img03: file(relativePath: { eq: "interview/03/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
